export class TypeAhead {
	constructor(input, sources) {
    this.input = $(input);
    this.sources = sources;
    this.suggestionLimit = 15;
    this.assertCatcomplete();
    this.bind();
	}

  assertCatcomplete() {
    let that = this;
    if (typeof $.fn.catcomplete !== 'function') {
      $.widget( "custom.catcomplete", $.ui.autocomplete, {

				options: {
					autoFocus: true
				},
        _create: function() {
          this._super();
          this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
        },

        _renderMenu: function( ul, items ) {
					let that = this;
          $.each( items, function( index, item ) {
            let li;
	          li = that._renderItemData( ul, item );
	          if ( item.category ) {
	            li.attr( "aria-label", item.category + " : " + item.label );
	          }
          });
        },

        _renderItem: function ( ul, item ) {
          let card = item.label;
          if (item.matches && item.matches.length && item.matches[0].indices) {
            card = that.insertSpans(card, item.matches[0].indices)
          }

          if (item.templateFn) {
            let itemData = item.loc;
            itemData.category = item.category;
            card = item.templateFn(itemData);
          }

          return $( "<li></li>" )
                .data("item.autocomplete", item)
                .append( "<a>" + card + "</a>" )
                .appendTo( ul );
        }
      });
    }
  }

  insertSpans(str, indices) {
    let out = str;
    for (let pair of indices.reverse()) {
      let [start, end] = pair;
      out = out.slice(0, start) + "<span class='match-highlight'>" + out.slice(start, end+1) + "</span>" + out.slice(end+1);
    }
    return out;
  }

  bind() {
    let that = this;

    /* Initialize the category autocomplete on the given input element */
    this.input.catcomplete({

      /* Aggregate all results into one results array, only when all results come back
       * from respective sources -- then call the response */
      source: (request, response) => {
        let results = Array(that.sources.length).fill(null);
        let aggregateResults = function() {
          // don't do anything until all results return
          if (results.filter ( result => result == null).length > 0 ) return;

          results = results.map((result) => result.slice(0, that.suggestionLimit));
          let flattenedResults = [].concat.apply([], results);
          response(flattenedResults);
        }
        for (const [i, source] of that.sources.entries()) {
          source.source(request.term, (sourceResults) => {
            results[i] = sourceResults;
            aggregateResults();
          });
        }
      },

      /* Opens the UI Menu by setting width */
      open: function(event, ui) {
        $('.ui-menu').css({
          'width': 'auto',
          'right': 0,
        });
      },

      /* Set the value of the input form to the clicked label */
      click: function(event, ui) {
        event.preventDefault();
        that.input.val(ui.item.getLabel(ui.item));
      },
      minLength: that.minActivationLength,
      appendTo: '.js-autocomplete-parent',
      position: {my: 'left top', at: 'left bottom'},

      /* When an item is selected, set the form value to the selected label */
      select: (e, ui) => {
        e.preventDefault();
        //that.setLastSelected(ui.item.param, ui.item.label);
        that.input.val(ui.item.getLabel(ui.item));
        ui.item.clickHandler(ui.item);
        ga('send', {
          'hitType': 'event',
          'eventCategory': ui.item.category,
          'eventLabel': $('#search-input').val()
        });
      }
    });
  }
}
