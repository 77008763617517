class Hours {
  static initClass(domScope) {
    this.autoRunInstances = true;
    this.instances = [];
    this.dom = domScope || document;
  }

  static loadHoursData() {
    for (let el of this.dom.querySelectorAll('.js-location-hours')) {
      el.locationHours = new this(el);
      this.instances.push(el);
    }
  }
  static runInstances() {
    return Array.from(this.instances).map((instanceElement) =>
      instanceElement.locationHours.run());
  }

  static loadAndRun() {
    this.loadHoursData();
    this.runInstances();
  }

  constructor(element, opts) {
    this.element = element;
    this.opts = opts || {};
    this.days = JSON.parse(this.element.dataset.days);

    let elOpts = {
      showOpenToday: this.element.dataset.showopentoday,
      todayFirst: this.element.dataset.todayfirst
    };

    let today = new Date();

    this.opts = Object.assign(this.opts, elOpts);

    // JS day of week -> Pages day of week
    // 0 -> 6
    // 1 -> 0
    // 2 -> 1
    // 3 -> 2

    this.todayIndex = today.getDay() === 0 ? 6 : today.getDay() - 1;
    this.currentTimeStamp = (today.getHours()*100) + today.getMinutes();
  }

  isOpenNow() {
    let currentDayData = this.days[this.todayIndex];
    let openNow = false;

    for (let interval of Array.from(currentDayData.intervals)) {
      if (interval.start === interval.end && interval.end === 0) {
        openNow = true;
        break;
      } else if (interval.start <= this.currentTimeStamp) {
        if (interval.end === 0) {
          openNow = true;
          break;
        } else if (interval.end >=this.currentTimeStamp) {
          openNow = true;
          break;
        }
      }
    }

    return openNow;
  }

  applyOpenToday() {
    for (let row of this.element.querySelectorAll('.js-day-of-week-row')) {
      let startIndex = row.dataset.dayOfWeekStartIndex;
      let endIndex = row.dataset.dayOfWeekEndIndex;

      if ((this.todayIndex >= startIndex) && (this.todayIndex <= endIndex)) {
        row.classList.add('is-today');
        row.classList.add('js-is-today');

        if (this.opts.showOpenToday != null) {
          let openTodayTarget = this.element.querySelector('.js-opentoday');
          if (openTodayTarget) {
            openTodayTarget.styles.display = 'block';
          }
        }
      }
    }
  }

  sortFromToday() {
    let bodies = this.element.querySelectorAll('.c-location-hours-details tbody');
    for (let table of this.element.querySelectorAll('.c-location-hours-details tbody')) {
      let rows = Array.from(table.childNodes);
      rows.reverse();
      let sortedRows = rows.slice(0, 7-this.todayIndex).reverse().concat(rows.slice(7-this.todayIndex).reverse());

      while (table.firstChild) {
        table.firstChild.parentElement.removeChild(table.firstChild);
      }
      sortedRows.forEach((elt) => {
        table.appendChild(elt);
      });
    }
  }

  applyOpenNow() {
    if ((this.opts.openNowTarget != null) && this.isOpenNow) {
      this.domScope.querySelector(this.opts.openNowTarget).classList.add('is-open-now');
    }
  }

  getTimeStamp(yextTime, twentyFourHourClock) {
    let hour = Math.floor(yextTime/100);
    let minutes = `00${yextTime%100}`;

    let timeStamp = "";
    if (twentyFourHourClock) {
      timeStamp = `${hour}:${minutes.slice(-2)}`;
    } else {
      timeStamp = `${hour}:${minutes.slice(-2)} AM`;
      if (hour === 12) { timeStamp = `12:${minutes.slice(-2)} PM`; }
      if (hour >= 13) { timeStamp = `${hour-12}:${minutes.slice(-2)} PM`; }
      if (hour === 0) { timeStamp = `12:${minutes.slice(-2)} AM`; }
    }

    return timeStamp
  }

  processTodayHours() {
    for (let instance of this.element.querySelectorAll('.js-is-today .js-location-hours-interval-instance')) {
      let twentyFourHourClock = (instance.dataset.twentyFourHourClock === 'true');
      let start = parseInt(instance.dataset.openIntervalStart);
      let end = parseInt(instance.dataset.openIntervalEnd);
      let startTimeStamp = this.getTimeStamp(start, twentyFourHourClock);
      let endTimeStamp = this.getTimeStamp(end, twentyFourHourClock);

      let today = new Date();
      let currentHour = (today.getHours()*100) + today.getMinutes();

      let endTime = '<span class="c-location-hours-today-day-hours-end-hour">' +
                  endTimeStamp +
                '</span>';
      let startTime = '<span class="c-location-hours-today-day-hours-start-hour">' +
                  startTimeStamp +
                '</span>';
      if ((start !== end) && ((start !== 0) || (end !== 0))) { // Not Open 24 hours
        if ((end === 0) && (currentHour > start)) {
          return instance.innerHTML = instance.dataset.midnightText;
        } else if (start < end) {
          if ((currentHour > start) && (currentHour < end)) {
            return instance.innerHTML = instance.dataset.openUntilText + ` ${endTime}`;
          } else if ((currentHour > end) && (end !== 0)) {
            instance.innerHTML = instance.dataset.closeAtText + ` ${endTime}`;
            return Array.from(instance.children).forEach(el => el.classList.add('currentlyClosed'));
          } else {
            if (instance.dataset.opensAtText) {
              return instance.innerHTML = instance.dataset.opensAtText + ` ${startTime}`;
            }
          }
        } else { // Open past midnight
          if (currentHour > start) {
            return instance.innerHTML = instance.dataset.openUntilText + ` ${endTime}`;
          }
        }
      }
    }
  }

  run() {
    this.applyOpenNow();
    this.applyOpenToday();
    this.processTodayHours();
    if (this.opts.todayFirst) {
      this.sortFromToday();
    }
    if ((Yext.Callbacks != null ? Yext.Callbacks.hoursProcessed : undefined) != null) {
      return Yext.Callbacks.hoursProcessed(this);
    }
  }
}

Hours.initClass();

export const LocationHours = Hours;
