import AutocompleteEndpointSource from 'js/search/modules/AutocompleteEndpointSource.js';
require('script-loader!.tmp/js/templates/common/modules/AutoItem.js');

export class Source {
  constructor(options = {}) {
    Object.assign(this, options);
    this.endpoint = this.endpoint || new AutocompleteEndpointSource({});
    this.clickHandler = this.clickHandler || function() {};
    this.templateFn = this.templateFn || common.AutoItem;
    this.getLabel = this.getLabel || function(item) { return item.label; }
    this.sort = this.sort || function(x, request) { return x; };
    this.maxResultsNum = this.maxResultsNum || 5;
  }

  source(request, cb) {
    let that = this;
    this.endpoint.source(request, function(source_results) {
      let results = source_results;
      if(results.length > 0){
        results = source_results.sort(that.sort);
      }
      results = results.slice(0, that.maxResultsNum);
      results = results.map((x) => {
        x.templateFn = that.templateFn;
        x.clickHandler = that.clickHandler;
        x.getLabel = that.getLabel;
        x.label = that.getLabel(x);
        return x;
      });
      cb(results);
    });
  }
}
