require('script-loader!.tmp/js/templates/common/modules/AutoItem.js');

export class AutocompleteResult {
  constructor(options = {}) {
    Object.assign(this, options);
    if (this.loc == null) {
      throw "There is no location for an autocomplete result";
    }
    this.clickHandler = this.clickHandler || function() {};
    this.templateFn = this.templateFn || common.AutoItem;
  }
}
