import { LocatorEvent, PinHoverEvent } from 'js/search/modules/Events.js';

export class ResultList {
  static setup() {
    for (let res of document.querySelectorAll('.ResultList-item')) {
      res.HoverHandler = new HoverHandler(res);
    }
  }
}

class HoverHandler {
  constructor(el) {
    this.element = el;
    this.target = el.id;
    this.isDefault = true;
    this.element.addEventListener('mouseover', () => {
      this.hoverHandler(true);
    });
    this.element.addEventListener('mouseout', () => {
      this.hoverHandler(false);
    })
    document.addEventListener(PinHoverEvent.eventTypeName, (event) =>{
      this.handleHoverEvent(event);
    });
  }

  hoverHandler(active) {
    document.dispatchEvent(new PinHoverEvent(this.target, active));
  }

  handleHoverEvent(event) {
    let isMyPin = event.detail.yextId === this.target;
    let needsHighlight = isMyPin && event.detail.active;
    let hasHighlight = !this.isDefault;
    if (needsHighlight && !hasHighlight) {
      this.element.classList.add('ResultList-item--isActive');
      this.isDefault = false;
    } else if (!needsHighlight && hasHighlight) {
      this.element.classList.remove('ResultList-item--isActive');
      this.isDefault = true;
    }
  }
}
