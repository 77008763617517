import { DistanceCalculator } from 'ts/components/DistanceCalculator/index.ts';

const distanceCalc = new DistanceCalculator();

const latLngFromPoint = (pt) => {
  return {
    latitude: pt.lat,
    longitude: pt.lng
  };
}

export class PanButton {
  constructor(buttonAction, options) {
    this.buttonAction = buttonAction || (() => {});

    options = options || {};
    this.buttonClass = options.buttonClass || 'js-pan-button';
    this.buttonText = options.buttonText || 'search this area';
    this.distanceThreshold = options.distanceThreshold || 5;

    this.button = this.createButton();
  };

  addToMap(map, parent) {
    if (!(map && parent)) { return; };

    this.map = map;
    this.parent = parent;
    this.parent.appendChild(this.button);

    this.previousCenter = map.getCenter();

    map.on('dragend', this.dragHandler.bind(this));
  }

  dragHandler() {
    const currentCenter = this.map.getCenter();
    const distance = distanceCalc.haversineDistance(latLngFromPoint(this.previousCenter), latLngFromPoint(currentCenter));
    if (distance > this.distanceThreshold) {
      this.showButton();
    }
  }

  showButton() {
    this.button.classList.add('is-visible');
    this.button.classList.remove('is-hidden');
  }

  hideButton() {
    this.button.classList.remove('is-visible');
    this.button.classList.add('is-hidden');
  }

  createButton() {
    const button = document.createElement('button');
    button.innerHTML = this.buttonText;
    button.classList.add(this.buttonClass);
    button.classList.add('is-hidden');

    button.addEventListener('click', () => {
      let currentCenter = this.map.getCenter();
      this.previousCenter = currentCenter;

      this.buttonAction(this.map, currentCenter);
      this.hideButton();
    });


    return button;
  }
}
