import { AutocompleteEndpointSource } from 'js/search/modules/AutocompleteEndpointSource.js';

export class MapboxEndpointSource extends AutocompleteEndpointSource {
  constructor(options = {}) {
    super(options);
    this.accessToken = this.accessToken || 'pk.eyJ1IjoieWV4dCIsImEiOiJqNzVybUhnIn0.hTOO5A1yqfpN42-_z_GuLw';
    this.decodeMode = this.decodeMode || 'mapbox.places';
    this.globalSearch = this.globalSearch || false;
    this.country = this.country || 'us';
    this.limit = this.limit || 5;
    this.url = null;
    this.category = 'location';
    this.param = 'q';
    this.appendScript();
  }

  // Source takes an input string and returns suggestions
  // of the form {label, category, param, value}
  source(request, cb, clickHandler, templateFn) {
    let that = this;
    this.url = `https://api.mapbox.com/geocoding/v5/${this.decodeMode}/${request}.json?access_token=${this.accessToken}&limit=${this.limit}${this.proximityString}`;
    if (!this.globalSearch) {
      this.url += `&country=${this.country}`;
    }

    $.ajax({
      url: this.url,
      data: null,
      dataType: 'json',
      timeout: 1000,
      success: (data) => {
        let places = data.features;
        let names = [];
        places.forEach(place => {
          if (place.place_name) {
            names.push(place.place_name);
          }
        });

        let results = names.map((x) => {
          return {
            label: x,
            category: that.category,
            param: that.param,
            loc: {label: x},
            matches: [],
          };
        });
        cb(results);
      },
      error: (err) => {
        console.log("autocomplete endpoint error", err);
        cb([]);
      }
    });
  }

  appendScript() {
    // TODO: figure out how to bundle these scripts locally
    let href = 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/smoothness/jquery-ui.css';
    let link = document.createElement('link');
    let scriptSrc = 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js';

    link.rel = 'stylesheet';
    link.href = href;
    document.body.appendChild(link);

    $.getScript(scriptSrc, (data, textStatus, jqxhr) => {});
  }
}
