import 'babel-polyfill';
import stickybits from 'stickybits';
import URI from 'urijs';

require('script-loader!.tmp/js/templates/components/Util/Util.js');
require('script-loader!.tmp/js/templates/components/Maps/Maps.js');
require('script-loader!.tmp/js/templates/components/Maps/GetDirections.js');
require('script-loader!.tmp/js/templates/components/address/address-override.js');
require('script-loader!.tmp/js/templates/components/address/i18n.js');
require('script-loader!.tmp/js/templates/components/address/fields.js');
require('script-loader!.tmp/js/templates/components/phone/phone-override.js');
require('script-loader!.tmp/js/templates/components/search/search.js');
require('script-loader!.tmp/js/templates/components/LocationHours/LocationHours.js');
require('script-loader!.tmp/js/templates/common/helpers.js');
require('script-loader!.tmp/js/templates/common/modules/Teaser.js');
require('script-loader!.tmp/js/templates/search/modules/search-box.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Locator/Ace-override.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Teaser-override.js');
require('script-loader!.tmp/js/templates/common/modules/Footer.js');
require('script-loader!.tmp/js/templates/common/modules/LocationName.js');
require('script-loader!.tmp/js/templates/url/url.js');
require('script-loader!.tmp/js/templates/search/modules/helpers.js');
require('script-loader!.tmp/js/templates/search/modules/results.js');
require('script-loader!.tmp/js/templates/common/modules/AutoCard.js'); // Autocomplete
require('script-loader!.tmp/js/templates/common/modules/AutoItem.js'); // Autocomplete

import { Maps } from 'js/components/Maps/index.js'
import { Locator } from 'js/components/Cobalt/Locator/Locator.js'
import { Form } from 'js/components/Cobalt/Locator/Form.js';
import { FilterModal } from 'js/components/Cobalt/Locator/FilterModal.js';
import { Instance as RendererAce } from 'js/components/Cobalt/Locator/RendererAce-override.js';
import { TypeAhead } from 'js/search/modules/TypeAhead.js';
import { AutocompleteEndpointSource } from 'js/search/modules/AutocompleteEndpointSource.js';
import { MapboxEndpointSource } from 'js/search/modules/MapboxEndpointSource.js';
import { Source } from 'js/search/modules/Source.js';
//import { Autocompleter } from 'js/common/modules/Autocompleter.js';

import { PanButton } from 'js/search/modules/PanButton.js';
import { AceMap as AceMapMapbox, MapSetup as MapSetupMapbox } from 'js/search/modules/LocatorAceMap.js';
import { AceMap as AceMapGoogle, MapSetup as MapSetupGoogle } from 'js/search/modules/LocatorAceMapGoogle.js';

export class LocatorAce {
  constructor() {}

  static init(options = {}) {
    Maps.loadAndRun();
    const configEl = document.getElementById('js-ace-config');
    const config = configEl ? JSON.parse(configEl.innerHTML) : {};
    if (config.provider && config.provider.toLowerCase() === 'google') {
      window.Yext.Maps = { AceMap: AceMapGoogle};
      MapSetupGoogle();
    } else {
      window.Yext.Maps = { AceMap: AceMapMapbox};
      MapSetupMapbox();
    }

    const locatorEl = document.getElementById('js-locator');
    const formEl = document.getElementById('search-form');
    const inputEl = document.getElementById('search-input');

    const initAndFixAutocomplete = () => {
      const locClickHandler = function(item) {
        locatorAce.submitHandler();
      }
      const MapboxEndpoint = new MapboxEndpointSource({});
      const MapboxSource = new Source({
        clickHandler: locClickHandler,
        endpoint: MapboxEndpoint,
        template: common.AutoItem
      });

      const sources = options.sources ? options.sources.concat([MapboxSource]) : [MapboxSource];
      const auto = new TypeAhead(inputEl, sources);
    }

    if (typeof google == 'undefined') {
      const script = document.createElement('script');
      if (config.provider && config.provider.toLowerCase() === 'google') {
        script.src= `//maps.googleapis.com/maps/api/js?v=3.31&${window.Yext.Maps.AceMap.instances[0].versionType}=${window.Yext.Maps.AceMap.instances[0].config.apiID}&libraries=places&channel=${window.Yext.Maps.AceMap.instances[0].config.channelId}&callback=window.Yext.Maps.AceMap.providerCallback`;
      } else {
        script.src=`//maps.googleapis.com/maps/api/js?v=3.31&libraries=places&client=gme-yextinc&channel=${window.location.hostname}`;
      }
      if (!config.disableAutocomplete) {
        script.onload = initAndFixAutocomplete;
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    } else {
      initAndFixAutocomplete();
    }

    const formSubmitter = document.getElementById('js-form-submit');
    const filtersEl = document.getElementById('filters')
    const externalInputEls = filtersEl ? filtersEl.querySelectorAll('input,select') : [];

    const filterModalOpenEl = document.getElementById('js-filters-open');
    const filterModal = filtersEl ? new FilterModal(filtersEl, filterModalOpenEl) : null;
    const form = new Form(formEl, formSubmitter, externalInputEls);

    // Polyfill for position sticky on IE.
    // This only works on mobile. It works on the body but can't handle overflowing containers.
    stickybits('.js-stickybits', {noStyles: true});
    const locatorAce = new Locator(locatorEl,
            form,
            RendererAce,
            filterModal && filterModal.updateFilterCount.bind(filterModal));

    const researchFunction = (map, center) => {
      let formValues = form.serialize()
      let params = new URI(`?${formValues}`);
      params.removeSearch('q');
      params.addSearch('q', `${center.lat},${center.lng}`);
      locatorAce.searchAndCallback(params.query(), (data) => {
        RendererAce.render(data, {keepCenterVisible: true})
      });
    };

    const researcher = new PanButton(researchFunction, {
      buttonClass: 'Locator-researchButton',
    });

    if (options.useReSearchButton) {
      for (const instance of AceMap.instances) {
        instance.element.addEventListener('map.prepared', () => {
          researcher.addToMap(instance.map, instance.element);
        });
      }
    }
  }
}
