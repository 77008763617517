import { AutocompleteResult } from 'js/search/modules/AutocompleteResult.js';

export class AutocompleteEndpointSource {
  constructor(options = {}) {
    Object.assign(this, options);
    this.category = options.category;
    this.param = options.param;
  }

  getIndicesOf(searchStr, str) {
    let searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    let startIndex = 0;
    let indices = [];
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
    let index;
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
  }

  // Source takes an input string and returns suggestions
  // of the form {label, category, param, value}
  source(request, cb, clickHandler, templateFn) {
    let that = this;
    let url = new URI();
    if (!this.param) {
      throw 'No param for filters, please provide field param name';
    }
    let filters = [{}];
    filters[0][this.param] = {"contains": [request] };
    url.query({ 'filters' : JSON.stringify(filters) });
    let matches = [];
    $.ajax({
      url: url,
      data: null,
      dataType: 'json',
      success: (data) => {
        let results = [];
        for (let i = 0;  i < data.locations.length; i++) {
          let loc = data.locations[i].loc;
          let result = new AutocompleteResult({
            label: loc.name ? loc.name : loc,
            category: that.category,
            param: that.param,
            matches: matches,
            loc: loc,
          });
          results.push(result);
        }
        cb(results);
      },
      error: (err) => {
        console.log("autocomplete endpoint error", err);
        cb([]);
      }
    });
  }
}
