import 'whatwg-fetch';
import 'babel-polyfill';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { SpinnerModal } from 'js/components/SpinnerModal/SpinnerModal.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { Maps } from 'js/components/Maps/index.js';

const Raven = require('raven-js/dist/raven.js');
Raven.config('https://13d7b1cf5db9462a8a6121dfd4d032c5@sentry.io/751790').install();
const URI = require('urijs');

import { Form } from 'js/components/Cobalt/Locator/Form.js';
import { FilterModal } from 'js/components/Cobalt/Locator/FilterModal.js';

import { HTML5Geolocation } from 'js/components/Util/Html5Geolocation.js';
HTML5Geolocation.initClass();

let queryIsEmpty = function(query) {
  return query == "?" || query == "";
}

export class Locator {
  constructor(el, form, renderer, stateChangeCallback) {
    this.el = el;
    this.inputEl = this.el.querySelector('.js-locator-input');
    this.resultsContainer = this.el.querySelector('.js-locator-resultsContainer');
    this.form = form;
    this.renderer = renderer;
    this.stateChangeCallback = stateChangeCallback || (() => {});
    this.virtualLoanOfficerPage = Math.floor(Math.random() * 6) + 1;;

    this.spinner = new SpinnerModal();

    this.form.bindSubmitHandler(this.submitHandler.bind(this));
    window.onpopstate = this.popHandler.bind(this);

    const geolocateButton = this.form.el.querySelector('.js-locator-geolocateTrigger');
    if (geolocateButton) {
      geolocateButton.addEventListener('click', () => {this.geolocate(this.inputEl)});
    }

    this.onLoad();
  }

  popHandler(evt) {
    this.renderer.render(evt.state);
    this.form.deserialize(window.location.search);
    this.stateChangeCallback();
  }

  async geolocate(input) {
    const name = input.name;
    this.spinner.showSpinner();
    HTML5Geolocation.getCurrentLocation(async (position) => {
      if (position.hasOwnProperty('latitude') && position.hasOwnProperty('longitude')) {
        const currentUrl = `${window.location.pathname}`;
        const queryString = `${name}=${position.latitude},${position.longitude}`;
        let results = null
        try {
          results = await this.search(queryString);
          const queryLocation = results.queryLocation;
          if (queryLocation) {
            input.value = `${queryLocation.city}, ${queryLocation.state} ${queryLocation.zip}`;
            window.history.pushState(results, "", `${currentUrl}?${name}=${queryLocation.city}, ${queryLocation.state} ${queryLocation.zip}`);
          } else {
            input.value = `${position.latitude},${position.longitude}`;
            window.history.pushState(results, "", `${currentUrl}?${name}=${position.latitude},${position.longitude}`);
          }
          this.renderer.render(results);
          this.spinner.hideSpinner();
        } catch(err) {
          console.log("Geolocation Error: ", err);
          Raven.captureException(err, {
            extra: {
              details: 'Error searching after geolocation',
              queryString: queryString
            }
          });
        }
      } else {
        console.log("Error: no position found.");
      }
    }, () => {
      this.spinner.hideSpinner();
      console.log("Error: no position found.");
    });
  }

  async onLoad() {
    const currentUrl = `${window.location.pathname}`;
    let currentQuery = this.form.stripInvalidParams(window.location.search);
    const locatorParamsEl = this.el.querySelector('.js-locator-params');
    this.templateParams = locatorParamsEl ? JSON.parse(locatorParamsEl.innerHTML) : {};
    Raven.setExtraContext({
      site: this.templateParams.siteInternalHostName ? this.templateParams.siteInternalHostName : 'unknown'
    });
    if (queryIsEmpty(currentQuery)) {
      const data = JSON.parse(this.el.querySelector('.js-locator-initialData').innerHTML);
      window.history.replaceState(data,"", `${currentUrl}`);
      return;
    }

    this.form.deserialize(currentQuery);
    window.history.replaceState({},"", `${currentUrl}${currentQuery}`);
    let data = null;
    try {
      data = await this.submitAndRender();
      if (!data.resultCount) {
        const initialQuery = data.query;
        currentQuery = 'title=Home%20Loan%20Originator&per=10&p='+this.virtualLoanOfficerPage;
        this.virtualLoanOfficerPage = (this.virtualLoanOfficerPage+1)%7;
        data = await this.submitAndRender(false, currentQuery, {"showNoResultsMessageWithResults": true, "initialQuery": initialQuery});
      }
      window.history.replaceState(data, "", `${currentUrl}?${currentQuery}`);
    } catch(err) {
      console.log("err: ", err);
      Raven.captureException(err, {
        extra: {
          details: 'Initial search after deserializing from url',
          queryString: currentQuery
        }
      });
    }
    this.stateChangeCallback();
  }

  async submitHandler(evt) {
    if (evt) evt.preventDefault();
    const currentUrl = `${window.location.pathname}`;
    let query = this.form.serialize();
    window.history.pushState({}, "", `${currentUrl}?${query}`);
    let data = null;
    try {
      data = await this.submitAndRender();
      if (!data.resultCount) {
        const initialQuery = data.query;
        query = 'title=Home%20Loan%20Originator&per=10&p='+this.virtualLoanOfficerPage;
        this.virtualLoanOfficerPage = (this.virtualLoanOfficerPage+1)%7;
        data = await this.submitAndRender(false, query, {"showNoResultsMessageWithResults": true, "initialQuery": initialQuery});
      }
      window.history.replaceState(data, "", `${currentUrl}?${query}`);
      $(this.inputEl).catcomplete("close");
    } catch(err) {
      console.log("err: ", err);
      Raven.captureException(err, {
        extra: {
          details: 'Standard search',
          queryString: query
        }
      });
    }
  }

  async submitAndRender(useFormInput = true, inputOverride, cbOptions = {}) {
    const query = useFormInput ? this.form.serialize() : inputOverride;
    return this.searchAndRender(query, cbOptions);
  }

  async searchAndCallback(query, cb, cbOptions = {}) {
    Yext.Analytics.send({eventType: 'search'});
    this.spinner.showSpinner();
    const startTime = performance.now();
    const results = await this.search(query);
    if (cb) {
      cb(results, cbOptions)
    }
    const finishTime = performance.now();
    if ((finishTime - startTime) > 500) {
      this.spinner.hideSpinner();
    } else {
      window.setTimeout(this.spinner.hideSpinner.bind(this.spinner), 500 - (finishTime - startTime));
    }
    return results
  }

  async searchAndRender(query, cbOptions = {}) {
    return this.searchAndCallback(query, this.renderer.render.bind(this.renderer), cbOptions);
  }

  async search(query) {
    const fetchInit = {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      }
    };
    try {
      const response = await fetch(`${this.form.getAction()}?${query}`, fetchInit);
      return response.json();
    } catch(err) {
      Raven.captureException(err, {
        extra: {queryString: query,}
      });
      return Promise.reject(e)
    }
  }
}
