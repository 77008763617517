import 'babel-polyfill';
import createFocusTrap from 'focus-trap/index.js';
import { PinClickEvent, PinHoverEvent, CardHoverEvent, CardClickEvent } from './Events.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { Maps } from 'js/components/Maps/index.js';
import { SearchMap } from 'js/search/modules/Google.js';

export class AceMap extends SearchMap {
  static initClass() {
    Maps.FactoryForProvider.Google = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.AceMap";
    this.breakPoint = 992;
  }

  constructor(args) {
    super(args);
    this.pinHeight = 42;
    this.pinWidth = 32;
    this.pins = [];
  }

  appendScript() {
    /*
      DO NOTHING: Since autocomplete is loaded elsewhere and loads the same API,
      let it load the script and execute the callback
    */
  }

  svgPin(label, backgroundColor, textColor) {
    if (label == null) { label = '•'; }
    if (backgroundColor == null) { backgroundColor = '#00945f'; }
    if (textColor == null) { textColor = 'white'; }
    return `
      <svg width='${this.pinWidth}'
           height='${this.pinHeight}'
          viewBox='0 0 ${this.pinWidth} ${this.pinHeight}'
          xmlns='http://www.w3.org/2000/svg'>
        <g fill="none" fill-rule="evenodd">
          <path d="m0 16c0 11.44 16 26 16 26s16-14.56 16-26c0-8.58-7.086-16-16-16s-16 7.42-16 16z"
                fill='${backgroundColor}'
                stroke='${textColor}'/>
          <text fill='${textColor}'
                font-family="SFProText-Bold, SF Pro Text"
                font-size="14"
                font-weight="bold">
            <tspan x='${this.pinWidth/2}'
                   y='${this.pinHeight/2}'
                   text-anchor='middle'>
              ${label}
            </tspan>
          </text>
        </g>
      </svg>
    `;
  }

  iconImage(loc, i, backgroundColor, textColor) {
    const url = `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, backgroundColor, textColor))}`;
    return {
      url: url,
      scaledSize: new google.maps.Size(32,42)
    };
  }

  registerResultHandlers() {
    registerResultHandlers();
  }

  removePins() {
    for (let pin of this.pins) {
      pin.setMap(null);
    }
    this.pins = [];
  }

  updateMap() {
    this.boundsForPins = new google.maps.LatLngBounds();
    this.infowindow = new google.maps.InfoWindow();

    this.pins = [];
    let idx = 0;

    for (let location of Array.from(this.allLocations)) {
      let pin = this.preparePin(idx, location, this.map);
      this.boundsForPins.extend(pin.position);
      this.pins.push(pin);
      idx++;
    }
    this.redrawMap();
  }

  prepareMap() {
    const map = super.prepareMap();
    this.constructor.map = map;
    return map;
  }

  preparePin(i, loc, m) {
    let icon = this.iconImage(loc, i);
    this.validatePinIcon(icon);
    const position = new google.maps.LatLng(loc.latitude, loc.longitude);
    let pin = new google.maps.Marker({
      position: position,
      icon,
      map: m,
      zIndex: 0,
      optimized: false // For IE <= 11 compat
    });

    pin.addListener('click', () => {
      this.clickHandler(loc, pin, i, m);
    });

    this.pins.push(pin);
    pin.isHovered = false;
    pin.isSelected = false;

    pin.addListener('mouseover', () => {
      this.hoverHandler(true, loc, pin, i, m);
    });
    pin.addListener('mouseout', () => {
      this.hoverHandler(false, loc, pin, i, m);
    })

    document.addEventListener(CardClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        window.map = m;
        const bounds = m.getBounds();
        if (!bounds.contains(position)) {
          m.panTo(position);
        }
        let icon = this.iconImage(loc, i, '#265745', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = true;
        pin.zIndex = 2;
      } else {
        let icon = this.iconImage(loc, i, '#00945f', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = false;
        pin.isHovered = false;
        pin.zIndex = 0;
      }
    });

    document.addEventListener(PinClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        let icon = this.iconImage(loc, i, '#265745', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = true;
      } else {
        let icon = this.iconImage(loc, i, '#00945f', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = false;
        pin.isHovered = false;
      }
    });

    document.addEventListener(CardHoverEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (pin.isSelected) {
        pin.isHovered = event.detail.active;
        return;
      }

      if (event.detail.yextId == myTarget && event.detail.active) {
        let icon = this.iconImage(loc, i, '#265745', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = true;
      } else {
        let icon = this.iconImage(loc, i, '#00945f', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = false;
      }
    });

    document.addEventListener(PinHoverEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (pin.isSelected) {
        pin.isHovered = event.detail.active;
        return;
      }
      if (event.detail.yextId == myTarget && event.detail.active) {
        let icon = this.iconImage(loc, i, '#265745', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = true;
      } else {
        let icon = this.iconImage(loc, i, '#00945f', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = false;
      }
    });

    return pin;
  }
}
AceMap.initClass();

const registerResultHandlers = () => {
  let results = document.getElementsByClassName('js-location-result');
  for (let result of Array.from(results)) {
    result.addEventListener('click', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardClickEvent(this.getAttribute('id')));
    });
    result.addEventListener('mouseover', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), true));
    });
    result.addEventListener('mouseout', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), false));
    });
  }
}

const registerDocumentHandlers = () => {
  document.addEventListener(PinClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (id == null) { return; }
    const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
    if (minWidthSearchMapBreakPointQuery.matches) {
      document.getElementById(id).classList.add('is-selected');
    }
    let toRemove = document.querySelectorAll(`.js-location-result.is-selected:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
      toChange.classList.remove('is-hovered');
    }

    const container = document.querySelector('.Locator-content');
    const isIE11 =!!window.MSInputMethodContext && !!document.documentMode;
    const stickyHeight = isIE11 ? 0 : document.querySelector('.Locator-searchWrapper').offsetHeight;

    const targetEl = document.getElementById(id);

    function isScrolledIntoView(elem) {
      const elBottom = $(elem).position().top + elem.offsetHeight;
      return (elBottom <= container.offsetHeight) && ($(elem).position().top >= stickyHeight);
    }

    if (!isScrolledIntoView(targetEl)) {
      $('.Locator-content').animate({
          scrollTop: container.scrollTop + $(targetEl).offset().top - document.querySelector('header').offsetHeight - stickyHeight,
      }, {duration: 600, queue: false});
    }
  });

  document.addEventListener(CardClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (!id) { return; }
    const targetEl = document.getElementById(id);
    let selector = `.js-location-result.is-selected`;
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
    }
    targetEl.classList.add('is-selected');
  });

  document.addEventListener(PinHoverEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    const targetEl = document.getElementById(id);
    if (e.detail.active) {
      targetEl.classList.add('is-hovered');
    }
    let selector = `.js-location-result.is-hovered`;
    if (e.detail.active) {
      selector += `:not(#${id})`;
    }
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-hovered');
    }
  });
}

export const MapSetup = () => {
  registerResultHandlers();
  registerDocumentHandlers();
}
