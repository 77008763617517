window.Yext = window.Yext || {};
import 'babel-polyfill';
require('script-loader!node_modules/jquery/dist/jquery.min.js');
require('script-loader!node_modules/urijs/src/URI.min.js');
require('script-loader!node_modules/jquery-ui/ui/widget.js');
require('script-loader!node_modules/jquery-ui/ui/widgets/menu.js');
require('script-loader!node_modules/jquery-ui/ui/unique-id.js');
require('script-loader!node_modules/jquery-ui/ui/widgets/autocomplete.js');
require('script-loader!node_modules/jquery-ui/ui/position.js');
require('script-loader!node_modules/jquery-ui/ui/keycode.js');
require('script-loader!node_modules/jquery-ui/ui/safe-active-element.js');


// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { InitSoy2JS } from 'js/components/Soy2js/Soy2js.js';
InitSoy2JS(window.Yext);

import { GoogleAdwords } from 'js/components/GoogleAdwords/index.js';
import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { Maps } from 'js/components/Maps/index.js';

import { LocatorAce } from 'js/components/Cobalt/Locator/LocatorAce-override.js';
import { ResultList } from 'js/common/modules/ResultList.js';

import { AutocompleteEndpointSource } from 'js/search/modules/AutocompleteEndpointSource.js';
import { Source } from 'js/search/modules/Source.js';

//soy2js
require('script-loader!.tmp/js/templates/components/Image/Image.js');
require('script-loader!.tmp/js/templates/common/modules/AutoCard.js');

Global.init();
GoogleAdwords.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  const clickHandler = function(item) {
    if (item && item.loc && item.loc.customByName &&
        item.loc.customByName.CompanyName.length && item.loc.customByName.CompanyName[0] === 'Citizens One') {
      window.open(
        `https://lo.citizensone.com/${item.loc.corporateCode}`,
        '_blank'
      );
    }
    else if (item && item.loc && item.loc.customByName &&
            item.loc.customByName.CompanyName.length && item.loc.customByName.CompanyName[0] === 'Citizens Bank') {
      window.open(
        `https://lo.citizensbank.com/${item.loc.corporateCode}`,
        '_blank'
      );
    }
    else if (item && item.loc && item.loc.website && item.loc.website.url) {
      window.open(
        item.loc.website.url,
        '_blank'
      );
    }
  }

  const getLabel = function(item) {
    if (item && item.loc && item.loc.customByName &&
        item.loc.customByName['FirstName'] && item.loc.customByName['LastName']) {
      return item.loc.customByName['FirstName'] + ' ' + item.loc.customByName['LastName'];
    } else {
      return item.label;
    }
  }

  const alphaSort = function(a, b){
      if(a.loc.customByName['LastName'] < b.loc.customByName['LastName']) return -1;
      if(a.loc.customByName['LastName'] > b.loc.customByName['LastName']) return 1;
      return 0;
  }

  const NmlsEndpoint = new AutocompleteEndpointSource({
    param: 'custom22099',
    category: 'NMLS ID'
  });
  const NameEndpoint = new AutocompleteEndpointSource({
    param: 'custom26191',
    category: 'Name'
  });

  const NmlsSource= new Source({
    clickHandler: clickHandler,
    endpoint: NmlsEndpoint,
    templateFn: common.AutoCard,
    getLabel: getLabel
  });

  const NameSource = new Source({
    clickHandler: clickHandler,
    endpoint: NameEndpoint,
    templateFn: common.AutoCard,
    getLabel: getLabel,
    sort: alphaSort
  });
  const sources = [NameSource, NmlsSource];

  LocationHours.loadAndRun();
  LocatorAce.init({
    sources: sources
  });
  ResultList.setup();
});
