require('script-loader!.tmp/js/templates/components/Util/Util.js');
require('script-loader!.tmp/js/templates/components/Maps/Maps.js');
require('script-loader!.tmp/js/templates/components/Maps/GetDirections.js');
require('script-loader!.tmp/js/templates/components/address/address-override.js');
require('script-loader!.tmp/js/templates/components/address/i18n.js');
require('script-loader!.tmp/js/templates/components/address/fields.js');
require('script-loader!.tmp/js/templates/components/phone/phone-override.js');
require('script-loader!.tmp/js/templates/components/search/search.js');
require('script-loader!.tmp/js/templates/components/LocationHours/LocationHours.js');
require('script-loader!.tmp/js/templates/common/helpers.js');
require('script-loader!.tmp/js/templates/common/modules/Teaser.js');
require('script-loader!.tmp/js/templates/search/modules/search-box.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Locator/Ace-override.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Teaser-override.js');
require('script-loader!.tmp/js/templates/common/modules/Footer.js');
require('script-loader!.tmp/js/templates/common/modules/LocationName.js');
require('script-loader!.tmp/js/templates/url/url.js');
require('script-loader!.tmp/js/templates/search/modules/helpers.js');
require('script-loader!.tmp/js/templates/search/modules/results.js');

import { Maps } from 'js/components/Maps/index.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { InitSoy2JS } from 'js/components/Soy2js/Soy2js.js';
import { OnReady } from 'js/components/Util/Browser.js';
InitSoy2JS(window.Yext);

class RendererAce {
  constructor(el) {
    OnReady(() => {
      this.el = document.getElementById('js-locator').querySelector('.js-locator-resultsContainer');
      const locatorParamsEl = document.getElementById('js-locator').querySelector('.js-locator-params');
      this.templateParams = locatorParamsEl ? JSON.parse(locatorParamsEl.innerHTML) : {};
      this.previousData = {};
    });
  }

  getLocationAddress(location) {
    let locationAddress = `${location.address1} ${location.address2} ${location.city} ${location.state} ${location.postalCode}`;
    return locationAddress.toLowerCase().replace(/[^a-z0-9]/gi,'');
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  shuffleLocations(locations) {
    let shuffledLocations = [];
    let locationsToShuffle = [];
    for (var i = 0; i < locations.length; i++) {
      let location = locations[i];
      let locationAddress = this.getLocationAddress(location.loc);
      let shuffleLocations = false;
      if (locationsToShuffle.length == 0) {
        locationsToShuffle.push(location);
      } else {
        if (locationAddress === this.getLocationAddress(locationsToShuffle[0].loc)) {
          locationsToShuffle.push(location);
        } else {
          shuffleLocations = true;
        }
      }
      if(shuffleLocations || i == locations.length - 1) {
        locationsToShuffle = this.shuffleArray(locationsToShuffle);
        for (var j = 0; j < locationsToShuffle.length; j++) {
          shuffledLocations.push(locationsToShuffle[j]);
        }
        locationsToShuffle = [location];

        if(shuffleLocations && i == locations.length - 1) {
          shuffledLocations.push(location);
        }
      }
    }

    return shuffledLocations;
  }

  render(data, options = {}) {
    data = Object.assign(data, this.templateParams);
    if (!data.locations) {
      this.el.innerHTML = cobalt.locator.ace_initial(data);
    } else {
      data.locations = this.shuffleLocations(data.locations);
      data.initialQuery = options.initialQuery;
      data.showNoResultsMessageWithResults = options.showNoResultsMessageWithResults;
      this.el.innerHTML = cobalt.locator.ace_results(data);
      LocationHours.loadAndRun();
    }

    const locations = data ? (data.locations || []) : [];

    for (let map of Maps.instances) {
      const rerenderMap = () => {
        map.allLocations = locations.map(key => Object.assign(key.loc, {url: key.url, altTagText: ''}));
        map.updateMap();
      };

      map.removePins();
      if (map.mapIsLoaded) {
         rerenderMap();
      } else {
        map.element.addEventListener('map-ready', () => {
          rerenderMap();
        });
      }
      map.registerResultHandlers();
    }
    this.previousData = data;
  }
}

const Instance = new RendererAce();

export {
  RendererAce,
  Instance,
}
