import 'babel-polyfill';
import URI from 'urijs';
import 'url-search-params-polyfill';
import 'formdata-polyfill';
import { InputHelper } from 'ts/components/Cobalt/Locator/InputHelper.ts';

export class Form {
  constructor(el, submitEl, externalInputEls) {
    if (!el) { return; };
    this.el = el;

    // To use an element outside of the form to submit the form
    // we need to dipatch a 'submit' event. Calling this.el.submit()
    // directly bypasses the submit handler.
    if (submitEl) {
      submitEl.addEventListener('click', () => {
        var event = document.createEvent('Event');
        event.initEvent('submit', true, true);
        this.el.dispatchEvent(event);
      });
    }

    let hiddenInputsContainer = document.createElement('div');
    hiddenInputsContainer.style.display = 'none';

    for (const input of externalInputEls) {
      let hiddenClone = input.cloneNode(true);
      hiddenClone.removeAttribute('id');
      hiddenClone.setAttribute('hidden', true);
      hiddenInputsContainer.appendChild(hiddenClone);
      hiddenClone.addEventListener('change', () => {
        if (!InputHelper.valuesMatch(input, hiddenClone)) {
          InputHelper.copyValue(hiddenClone, input);
        }
      });
      input.addEventListener('change', () => {
        if (!InputHelper.valuesMatch(input, hiddenClone)) {
          InputHelper.copyValue(input, hiddenClone);
        }
      });
    }
    this.el.appendChild(hiddenInputsContainer);
  };

  bindSubmitHandler(func) {
    this.el.addEventListener('submit', func);
  }

  reset() {
    this.el.reset();
    for (const input of Array.from(this.el.elements)) {
      InputHelper.triggerChange(input);
    }
  }

  serialize() {
    let data = new FormData(this.el);
    let searchParams = new URLSearchParams();

    for (let [key, value] of data.entries()) {
      searchParams.append(key, value);
    }

    return searchParams.toString();
  }

  getAction() {
    return this.el.action;
  }

  stripInvalidParams(query) {
    query = new URI(query);
    let params = query.query(true);
    for (let param in params) {
      if (!this.el[param]) {
        query.removeSearch(param);
      }
    }

    return query.toString();
  }

  deserialize(query) {
    this.reset();
    let params = new URI(query).query(true);
    let inputs = this.getFieldsByName();

    for (let param in params) {
      let values = params[param];
      if (!Array.isArray(values)) {
        values = [values];
      }
      let myInputs = inputs[param] ? inputs[param] : [];
      for (let [fieldIndex, field] of myInputs.entries()) {
        for (let [valueIndex, value] of values.entries()) {
          setInputElement(field, fieldIndex, value, valueIndex);
          InputHelper.triggerChange(field);
        }
      }
    }
  }

  getFieldsByName() {
    let fieldsByName = {};
    const inputEls = Array.from(this.el.elements).filter(el => el.name);
    for (let el of inputEls) {
      if (!fieldsByName[el.name]) fieldsByName[el.name] = [];
      fieldsByName[el.name].push(el);
    }

    return fieldsByName;
  }
}

let setInputElement = function(element, elIdx, value, valIdx) {
  const property = InputHelper.getPropertyToUpdate(element);

  if (property == 'value' && elIdx == valIdx) {
    element.value = value;
  } else if (property == 'checked' || property == 'selected') {
    let availableValues = [];

    if (element.options) {
      availableValues.push(...element.options);
    } else {
      availableValues.push(element);
    }

    if (element.multiple && valueIdx == 0) {
      element.selectedIndex = -1;
    }

    for (let field of availableValues) {
      if (field.value == value) {
        field[property] = true;
      }
    }
  }
}
