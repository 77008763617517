import 'babel-polyfill';
import createFocusTrap from 'focus-trap/index.js';
import { PinClickEvent, PinHoverEvent, CardHoverEvent, CardClickEvent } from './Events.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { Maps } from 'js/components/Maps/index.js';
import { SearchMap } from 'js/search/modules/MapboxGL.js';

export class AceMap extends SearchMap {
  static initClass() {
    Maps.FactoryForProvider.MapboxGL = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.AceMap";
    this.breakPoint = 992;
  }

  constructor(args) {
    super(args);
    this.pinHeight = 45;
    this.pinWidth = 34;
  }

  iconImage(loc, i, backgroundColor, textColor) {
    return `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, backgroundColor, textColor))}`;
  }

  registerResultHandlers() {
    registerResultHandlers();
  }

  removePins() {
    for (let pin of this.pins) {
      pin.remove();
    }
    this.pins = [];
  }

  updateMap(options = {}) {
    let boundsOptions = Object.assign({}, this.boundsOptions);

    const map = this.map;
    let marker;
    this.bounds = new mapboxgl.LngLatBounds();
    let idx = 0;
    for (let location of Array.from(this.allLocations)) {
      let coord = new mapboxgl.LngLat(location.longitude, location.latitude);
      marker = this.preparePin(idx, location, map, coord);

      this.pins.push(marker);
      this.bounds.extend(coord);
      idx++;
    }
    if (options.keepCenterVisible) {
      this.bounds.extend(map.getCenter());
      boundsOptions.animate = true;
      boundsOptions.linear = false;
      boundsOptions.duration = 300;
    }

    if (this.allLocations.length > 0) {
      this.options.center = this.bounds.getCenter();
    } else {
      this.options.center = new mapboxgl.LngLat(this.longitude, this.latitude);
      this.options.zoom = 3;
    }

    if (this.pins.length > 0) {
      for (marker of Array.from(this.pins)) {
        marker.addTo(map);
      }
      map.fitBounds(this.bounds, boundsOptions);
    }
  }

  prepareMap() {
    const map = super.prepareMap();
    this.constructor.map = map;
    return map;
  }

  preparePin(i, loc, map, latLng) {
    let wrapper = document.createElement('div');
    let button = document.createElement('button');
    button.className = 'custom-icon-button';
    let icon = $(this.svgPin(i+1));
    let image = icon[0];

    button.setAttribute('data-lon', loc.longitude);
    button.setAttribute('data-lat', loc.latitude);

    button.addEventListener('mouseover', () => {
      button.classList.add('is-hovered');
      wrapper.isHovered = true;
      document.dispatchEvent(new PinHoverEvent(`js-yl-${loc.id}`, true));
    });
    button.addEventListener('mouseout', () => {
      button.classList.remove('is-hovered');
      wrapper.isHovered = false;
      document.dispatchEvent(new PinHoverEvent(`js-yl-${loc.id}`, false));
    })

    button.addEventListener('click', function() {
      document.dispatchEvent(new PinClickEvent(`js-yl-${loc.id}`));
    });

    button.appendChild(image);
    wrapper.appendChild(button);
    wrapper.className = 'custom-icon js-custom-icon';

    wrapper.isHovered = false;
    wrapper.isSelected = false;

    document.addEventListener(PinClickEvent.eventTypeName, event => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        button.classList.add('is-selected');
        wrapper.isSelected = true;
      } else {
        button.classList.remove('is-selected');
        button.classList.remove('is-hovered');
        wrapper.isSelected = false;
        wrapper.isHovered = false;
      }
    });

    document.addEventListener(CardHoverEvent.eventTypeName, event => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget && event.detail.active) {
        button.classList.add('is-hovered');
        wrapper.isHovered = true;
      } else {
        button.classList.remove('is-hovered');
        wrapper.isHovered = false;
      }
    });

    document.addEventListener(CardClickEvent.eventTypeName, event => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        button.classList.add('is-selected');
        wrapper.isSelected = true;
        const bounds = map.getBounds();
        const preBounds = bounds.toString();
        const afterBounds = bounds.extend(latLng).toString();
        if (preBounds != afterBounds) {
          map.panTo(latLng);
        }
      } else {
        button.classList.remove('is-hovered');
        button.classList.remove('is-selected');
        wrapper.isSelected = false;
        wrapper.isHovered = false;
      }
    });

    document.addEventListener(PinHoverEvent.eventTypeName, (event) => {
      if (wrapper.isSelected) { return; }
      const currentlyHovered = event.detail.yextId == `js-yl-${loc.id}` && event.detail.active;
      if (!wrapper.isHovered && currentlyHovered) {
        button.classList.add('is-hovered');
        wrapper.isHovered = true;
        wrapper.style.zIndex = "1";
      } else if (wrapper.isHovered && !currentlyHovered) {
        button.classList.remove('is-hovered');
        wrapper.isHovered = false;
        wrapper.style.zIndex = "0";
      }
    });

    let marker = new mapboxgl.Marker(wrapper, {offset: new mapboxgl.Point(-this.pinWidth/2, -this.pinHeight/2)}).setLngLat(latLng);
    return marker;
  }
}
AceMap.initClass();

let registerResultHandlers = function() {
  let results = document.getElementsByClassName('js-location-result');
  for (let result of Array.from(results)) {
    result.addEventListener('click', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardClickEvent(this.getAttribute('id')));
    });
    result.addEventListener('mouseover', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), true));
    });
    result.addEventListener('mouseout', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), false));
    });
  }
};

let registerDocumentHandlers = function() {
  document.addEventListener(PinClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (id == null) { return; }
    const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.AceMap.breakPoint}px)`);
    if (minWidthSearchMapBreakPointQuery.matches) {
      document.getElementById(id).classList.add('is-selected');
    }
    let toRemove = document.querySelectorAll(`.js-location-result.is-selected:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
      toChange.classList.remove('is-hovered');
    }

    const container = document.querySelector('.Locator-content');
    const isIE11 =!!window.MSInputMethodContext && !!document.documentMode;
    const stickyHeight = isIE11 ? 0 : document.querySelector('.Locator-searchWrapper').offsetHeight;

    const targetEl = document.getElementById(id);

    function isScrolledIntoView(elem) {
      const elBottom = $(elem).position().top + elem.offsetHeight;
      return (elBottom <= container.offsetHeight) && ($(elem).position().top >= stickyHeight);
    }

    if (!isScrolledIntoView(targetEl)) {
      $('.Locator-content').animate({
          scrollTop: container.scrollTop + $(targetEl).offset().top - document.querySelector('header').offsetHeight - stickyHeight,
      }, {duration: 600, queue: false});
    }
  });

  document.addEventListener(CardClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (!id) { return; }
    const targetEl = document.getElementById(id);
    let selector = `.js-location-result.is-selected`;
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
    }
    targetEl.classList.add('is-selected');
  });

  document.addEventListener(PinHoverEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    const targetEl = document.getElementById(id);
    if (e.detail.active) {
      targetEl.classList.add('is-hovered');
    }
    let selector = `.js-location-result.is-hovered`;
    if (e.detail.active) {
      selector += `:not(#${id})`;
    }
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-hovered');
    }
  });
};


export const MapSetup = () => {
  registerResultHandlers();
  registerDocumentHandlers();
}
